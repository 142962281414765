import React, {useState, useEffect, FunctionComponent} from 'react';
import {navigate} from 'gatsby';
import {useErrorHandler} from 'react-error-boundary';
import SEO from '../SEO';
import {Skeleton} from '../../styles/page';
import {BasicInfo} from '../BasicInfo';
import {ChangePassword} from '../ChangePassword';
import PlanInfo from '../PlanInfo';
import {PasswordBox} from './AccountDetails.styles';
import {useAuth} from '../../services/auth';
import {PlanData} from '../../utils/plans';
import {User} from '@focusrite-novation/ampify-api';

type AccountDetailsProps = {
  apiUrl: string;
  planData: PlanData;
};

export const AccountDetails: FunctionComponent<AccountDetailsProps> = ({
  planData,
  apiUrl,
}) => {
  const handleError = useErrorHandler();
  const {getUserInfo} = useAuth();
  const [userData, setUserData] = useState<User>(null);
  const [isLoading, setIsLoading] = useState(true);

  function onChange(fieldToChange: string, value: string) {
    setUserData({
      ...userData,
      [fieldToChange]: value,
    });
  }

  useEffect(() => {
    async function fetchUserData() {
      try {
        const user = await getUserInfo(apiUrl);
        if (!user) {
          navigate('/login?continue=/my-account');
          return;
        }

        setUserData(user);
        setIsLoading(false);
      } catch (error) {
        handleError(error);
        navigate('/login?continue=/my-account');
      }
    }

    fetchUserData();
  }, [apiUrl]);

  return (
    <>
      <SEO title="My Account" />
      {userData && (
        <>
          <BasicInfo
            apiUrl={apiUrl}
            isLoading={isLoading}
            onChange={onChange}
            userData={userData}
          />
          <PlanInfo
            apiUrl={apiUrl}
            isLoading={isLoading}
            plans={userData.plans}
            planData={planData}
          />
        </>
      )}

      {isLoading ? (
        <PasswordBox>
          <Skeleton style={{width: '150px'}} />
        </PasswordBox>
      ) : (
        <ChangePassword apiUrl={apiUrl} />
      )}
    </>
  );
};
