import React, {FunctionComponent} from 'react';
import {Dropdown} from './Select.styles';
import {SelectProps} from './Select.types';

export const Select: FunctionComponent<SelectProps> = ({
  defaultValue,
  onChange,
  children,
  ...rest
}) => (
  <Dropdown value={defaultValue} onChange={onChange} {...rest}>
    {children}
  </Dropdown>
);
