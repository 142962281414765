export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export const Pricing: {[key: string]: string} = {
  [Currency.EUR]: '€7.99',
  [Currency.USD]: '$8.99',
  [Currency.GBP]: '£7.99',
};
