import React, {ChangeEvent, FunctionComponent} from 'react';
import {countries} from 'countries-list';
import {Select} from '../Select';

interface CountrySelectorProps {
  id?: string;
  error?: boolean;
  selected: string;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export const CountrySelector: FunctionComponent<CountrySelectorProps> = (
  props
) => {
  const options: JSX.Element[] = [];

  const sortedCountries = Object.entries(countries).map((country) => ({
    code: country[0],
    name: country[1]['name'],
  }));

  sortedCountries
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((country) =>
      options.push(
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      )
    );

  return (
    <Select
      value={props.selected}
      onChange={props.onChange}
      data-test-id="country"
      {...props}
    >
      {options}
    </Select>
  );
};
