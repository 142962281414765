import React, {useState, FunctionComponent} from 'react';
import {
  Box,
  Skeleton,
  Item,
  FormTitle,
  Label,
  ButtonSpacing,
  Spacer,
} from '../../styles/page';
import {Extended} from './BasicInfo.styles';
import {Text, Password} from '../Inputs';
import {isValidEmail} from '../../utils/validators';
import {DisplayMessage, DisplayMessageTypes} from '../DisplayMessage';
import {CountrySelector} from '../CountrySelector';
import {useAuth} from '../../services/auth';
import {User, UserApi} from '@focusrite-novation/ampify-api';
import {createAuthedApi} from '../../api';
import {parseApiError} from '../../api';

interface BasicInfoProps {
  apiUrl: string;
  onChange(fieldToChange: string, value: string): void;
  userData: User;
  isLoading: boolean;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  currentPassword?: string;
}

export const BasicInfo: FunctionComponent<BasicInfoProps> = ({
  onChange,
  userData,
  isLoading,
  apiUrl,
}) => {
  const {getUserInfo} = useAuth();
  const [updated, setUpdated] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState<FormErrors>({});

  const validate = () => {
    const errors: FormErrors = {} as any;

    const {firstName, lastName, email} = userData;

    if (firstName?.length === 0) {
      errors.firstName = 'Please enter your first name';
    }

    if (lastName?.length === 0) {
      errors.lastName = 'Please enter your last name';
    }

    if (!isValidEmail(email)) {
      errors.email = 'Please enter a valid email';
    }

    if (requiresPassword && currentPassword.length < 6) {
      errors.currentPassword =
        'Please enter your password to make these changes';
    }

    return errors;
  };

  const sendRequest = async (errors: FormErrors) => {
    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      const {firstName, lastName, email, country} = userData;
      const api = createAuthedApi(UserApi, apiUrl);
      await api.putUserDetails(userData.id, {
        firstName,
        lastName,
        email,
        country,
        password: {existing: currentPassword},
      });

      getUserInfo(apiUrl);
      setUpdated(true);
      setRequiresPassword(false);
    } catch (error) {
      try {
        const apiError = parseApiError(error);
        const message = apiError?.message;

        if (message?.includes('missing or incorrect')) {
          setErrors({
            currentPassword: 'Your password is incorrect. Please try again.',
          });
        } else if (message?.includes('already taken')) {
          setErrors({email: 'Sorry, this email address is already in use.'});
        } else {
          // an error from customer portal we haven't seen before, trigger sentry
          // to send it up so we have visibility
          console.error(message);
          setErrorMessage(message);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(
          'Sorry, there was a problem updating your user information.'
        );
      }
    }

    setIsSubmitting(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    setUpdated(false);
    setIsSubmitting(true);
    setErrorMessage('');
    setErrors({});

    sendRequest(validate());

    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        {updated ? (
          <DisplayMessage
            type={DisplayMessageTypes.SUCCESS}
            data-test-id="basic_info_success"
          >
            <div>Successully updated your personal information</div>
          </DisplayMessage>
        ) : null}
        {errorMessage ? (
          <DisplayMessage data-test-id="basic_info_error">
            <div>{errorMessage}</div>
          </DisplayMessage>
        ) : null}
        <FormTitle>Profile</FormTitle>
        <Spacer />
        <Item>
          {isLoading ? (
            <Skeleton style={{width: '120px'}} />
          ) : (
            <>
              <Label
                error={Boolean(errors.firstName)}
                data-test-id="first_name_label"
                htmlFor="first_name"
              >
                <b>{errors.firstName || 'First Name'}</b>
              </Label>
              <Text
                id="first_name"
                data-test-id="first_name"
                error={Boolean(errors.firstName)}
                value={userData.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange('firstName', e.target.value);
                }}
              />
            </>
          )}
        </Item>
        <Item>
          {isLoading ? (
            <Skeleton style={{width: '170px'}} />
          ) : (
            <>
              <Label
                error={Boolean(errors.lastName)}
                data-test-id="last_name_label"
                htmlFor="last_name"
              >
                <b>{errors.lastName || 'Last Name'}</b>
              </Label>
              <Text
                id="last_name"
                data-test-id="last_name"
                error={Boolean(errors.lastName)}
                value={userData.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange('lastName', e.target.value);
                }}
              />
            </>
          )}
        </Item>
        <Item>
          {isLoading ? (
            <Skeleton style={{width: '200px'}} />
          ) : (
            <>
              <Label
                error={Boolean(errors.email)}
                data-test-id="email_label"
                htmlFor="email"
              >
                <b>{errors.email || 'Email'}</b>
              </Label>
              <Text
                id="email"
                error={Boolean(errors.email)}
                data-test-id="email"
                value={userData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange('email', e.target.value);
                  setRequiresPassword(true);
                }}
              />
            </>
          )}
        </Item>
        <Item>
          {isLoading ? (
            <Skeleton style={{width: '180px'}} />
          ) : (
            <>
              <Label error={Boolean(errors.country)} htmlFor="country">
                <b>{errors.country || 'Country'}</b>
              </Label>
              <CountrySelector
                selected={userData.country}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  onChange('country', e.target.value);
                }}
              />
            </>
          )}
        </Item>
        <Item />
        {requiresPassword ? (
          <Extended>
            <Item>
              <Label
                error={Boolean(errors.currentPassword)}
                htmlFor="current_password"
                data-test-id="current_password_label"
              >
                <b>{errors.currentPassword || 'Confirm Your Password'}</b>
              </Label>
              <Password
                error={Boolean(errors.currentPassword)}
                id="current_password"
                data-test-id="current_password"
                value={currentPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentPassword(e.target.value)
                }
              />
            </Item>
          </Extended>
        ) : null}

        {isLoading === false ? (
          <ButtonSpacing showSpinner={isSubmitting} data-test-id="save_changes">
            Save Changes
          </ButtonSpacing>
        ) : null}
      </Box>
    </form>
  );
};
