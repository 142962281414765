import React, {useState, useEffect, Fragment} from 'react';
import styled from 'styled-components';
import {Link, navigate} from 'gatsby';
import {
  Box,
  Skeleton,
  Item,
  FormTitle,
  Separator,
  Spacer,
} from '../styles/page';
import {Button} from './Button';
import Colours from '../constants/colours';
import Typography from '../constants/typography';
import ExternalLink from './ExternalLink';
import {PlanData, generatePlanDefinitions} from '../utils/plans';
import {CardIcon} from './Icon/CardIcon';
import {useAuth} from '../services/auth';
import {
  UserApi,
  GetUserBillingInfoPaymentMethodCardTypeEnum as CardType,
  PaymentsApi,
  UserPlanStateEnum,
} from '@focusrite-novation/ampify-api';
import {createApi, createAuthedApi} from '../api';

interface PlanInfoProps {
  apiUrl: string;
  plans: string[];
  isLoading: boolean;
  planData: PlanData;
}

interface PlanDefinition {
  id: string;
  title: string;
  description: string;
  testid: string;
  colour: {
    primary: string;
    sparkle: string;
  };
  contact: {
    prefix: string;
    description: string;
    link: string;
    external: boolean;
  };
}

interface PlanImageProps {
  fill: string;
  sparkles: string;
  title: string;
  testid: string;
}

const PlanImage = (props: PlanImageProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100"
    data-test-id={props.testid}
  >
    <defs>
      <linearGradient id="b" x1="99.066%" x2="5.396%" y1="51.183%" y2="49.089%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".34" />
        <stop offset="100%" stopOpacity=".5" />
      </linearGradient>
      <rect id="a" width="100%" height="100%" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill={props.fill} xlinkHref="#a" />
      <use fill="url(#b)" fillOpacity=".5" xlinkHref="#a" />
      <circle cx="103.5" cy="112.5" r="57.5" fill="#F9F9F9" fillOpacity=".05" />
      <circle cx="624" cy="43" r="43" fill="#F9F9F9" fillOpacity=".05" />
      <circle cx="263" cy="33" r="11" fill="#F9F9F9" fillOpacity=".05" />
      <circle cx="465" cy="15" r="11" fill="#F9F9F9" fillOpacity=".05" />
      <circle cx="343.5" cy="113.5" r="18.5" fill="#F9F9F9" fillOpacity=".05" />
      <g fill={props.sparkles} transform="translate(58 13)">
        <polygon
          fillOpacity=".65"
          points="162.5 57 171.5 62.5 165.5 67.5 155 62.5"
        />
        <polygon
          fillOpacity=".7"
          points="61.5 20 70.5 25.5 64.5 30.5 54 25.5"
          transform="rotate(17 62.25 25.25)"
        />
        <polygon
          fillOpacity=".3"
          points="486.967 34.957 494.058 39.148 489.331 42.957 481.058 39.148"
          transform="rotate(65 487.558 38.957)"
        />
        <polygon fillOpacity=".55" points="2.5 67 5 70 .5 73 0 70" />
        <polygon fillOpacity=".6" points="288.5 24 291 27 286.5 30 286 27" />
        <polygon
          fillOpacity=".6"
          points="520.5 0 523 3 518.5 6 518 3"
          transform="rotate(86 520.5 3)"
        />
        <polygon fillOpacity=".3" points="4 2 8 4.5 9 9 0 9" />
        <polygon
          fillOpacity=".3"
          points="464 85 468 87.5 469 92 460 92"
          transform="rotate(95 464.5 88.5)"
        />
      </g>
      <text
        fill="#FFF"
        fontFamily="TT Commons, Helvetica Neue, helvetica, arial, sans-serif"
        fontSize="30"
        fontWeight="bold"
        textAnchor="end"
      >
        <tspan x="95%" y="80%">
          {props.title}
        </tspan>
      </text>
    </g>
  </svg>
);

const PlanInfo = (props: PlanInfoProps) => {
  const {isSubscriber} = useAuth();
  const [planData, setPlanData] = useState<PlanDefinition>(Object);
  const [hasPlans, setHasPlans] = useState<boolean>(true);
  const [lastFour, setLastFour] = useState<string>('');
  const [cardType, setCardType] = useState<CardType>(CardType.Unknown);
  const [expiry, setExpiry] = useState<string>('');
  const [hasPremium, setHasPremium] = useState<boolean>(false);
  const [nextBillingDate, setNextBillingDate] = useState<string>('');

  const planDefinitions = generatePlanDefinitions(props.planData);

  useEffect(() => {
    if (props.plans) {
      setHasPlans(props.plans.length > 0);

      for (let index = 0; index < planDefinitions.length; index++) {
        if (props.plans.indexOf(planDefinitions[index].id) != -1) {
          setPlanData(planDefinitions[index]);
          break;
        }
      }
    }
  }, [props.plans]);

  useEffect(() => {
    if (props.planData.premiumId === planData.id) {
      setHasPremium(true);
    }
  }, [props.planData, planData]);

  if (!hasPlans && !props.isLoading) {
    return null;
  }

  useEffect(() => {
    async function getBillingInfo() {
      if (props.isLoading === false && isSubscriber) {
        try {
          const api = createAuthedApi(PaymentsApi, props.apiUrl);
          const billingInfoResponse = await api.getUserBillingInfo();
          const billingInfo = billingInfoResponse.data;

          setCardType(billingInfo.paymentMethod.cardType);
          setLastFour(billingInfo.paymentMethod.lastFour);
          setExpiry(
            `${billingInfo.paymentMethod.expMonth}/${billingInfo.paymentMethod.expYear}`
          );
        } catch (error) {
          console.error(error);
        }
      }
    }

    getBillingInfo();
  }, [props.isLoading]);

  useEffect(() => {
    async function getPlans() {
      const api = createAuthedApi(PaymentsApi, props.apiUrl);
      const plansResponse = await api.getUserPlans();
      const subscriptionPlan = plansResponse.data.plans.find(
        (plan) => plan.id === props.planData.premiumId
      );
      if (subscriptionPlan !== undefined && subscriptionPlan.nextBillingDate) {
        switch (subscriptionPlan.state) {
          case UserPlanStateEnum.Active:
            setNextBillingDate(
              `Your next billing date is ${new Date(
                subscriptionPlan.nextBillingDate
              ).toLocaleDateString()}`
            );
            break;
          case UserPlanStateEnum.Canceled:
            setNextBillingDate(
              `Your subscription is cancelled and will end on ${new Date(
                subscriptionPlan.nextBillingDate
              ).toLocaleDateString()}`
            );
            break;
          case UserPlanStateEnum.Paused:
            setNextBillingDate(`Your subscription is paused`);
            break;
          default:
            console.error(
              `Subscription plan status was undefined (${subscriptionPlan.state})`
            );
            break;
        }
      }
    }

    getPlans();
  }, [props.planData, props.apiUrl]);

  return (
    <PlanBox>
      <FormTitle>Your Plan</FormTitle>
      <Spacer />
      <Item>
        {props.isLoading ? (
          <Fragment>
            <Skeleton style={{width: '100%', height: '6rem'}} />
            <Spacer />
            <Skeleton style={{width: '80%'}} />
          </Fragment>
        ) : (
          <Fragment>
            <PlanItem>
              <PlanImage
                fill={planData.colour.primary}
                sparkles={planData.colour.sparkle}
                title={planData.title}
                testid={planData.testid}
              ></PlanImage>
            </PlanItem>
            <DescriptionLabel data-test-id="plan_description">
              {planData.description}
            </DescriptionLabel>
            {hasPremium && lastFour ? (
              <Fragment>
                <Spacer />
                <Separator />
                <Spacer />
                <CardInformation>
                  <CardIcon width="40px" cardType={cardType} />
                  <CardNumber>
                    Card ending in <b>{lastFour}</b>
                  </CardNumber>
                  <ChangeCardLink to="/billing-info">
                    Change billing info
                  </ChangeCardLink>
                </CardInformation>
              </Fragment>
            ) : null}

            <Spacer />
            <Separator />
            <Spacer />

            {hasPremium && nextBillingDate !== '' && (
              <BillingDateLabel data-test-id="plan_next_billing">
                {nextBillingDate}
              </BillingDateLabel>
            )}

            <ContactLabel data-test-id="plan_contact">
              {planData.contact.prefix}
              <Spacer />
              {planData.contact.external ? (
                <ExternalLink
                  testId="support-link"
                  title={planData.contact.description}
                  href={planData.contact.link}
                  classNames={['plans_external_link']}
                >
                  {planData.contact.description}
                </ExternalLink>
              ) : (
                <Fragment>
                  <PlanDescription>
                    <Button
                      onClick={() => navigate(planData.contact.link)}
                      data-test-id="contact-description-button"
                    >
                      {planData.contact.description}
                    </Button>
                  </PlanDescription>
                </Fragment>
              )}
            </ContactLabel>
          </Fragment>
        )}
      </Item>
    </PlanBox>
  );
};

const PlanItem = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 1.5rem;
  margin-top: -0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 3px;
`;

const DescriptionLabel = styled.label`
  display: block;
  font-size: ${Typography.subtitle.desktop.fontSize};
  font-weight: bold;
  color: ${Colours.grey[800]};
  margin-top: 0rem;
  margin-bottom: 0rem;
  width: 78%;
`;

const ContactLabel = styled.div`
  display: block;
  font-size: ${Typography.body.desktop.fontSize};
  color: ${Colours.grey[600]};
  margin-top: 0rem;
  margin-bottom: 0;
`;

const PlanDescription = styled.div``;

const CardInformation = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-gap: 0.5rem;
  align-items: center;
`;

const CardNumber = styled.div`
  transform: translateY(2px);
`;

const ChangeCardLink = styled(Link)`
  font-size: 1.2rem;
  transform: translateY(3px);
`;

const PlanBox = styled(Box)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const BillingDateLabel = styled.label`
  display: block;
  font-size: ${Typography.body.desktop.fontSize};
  color: ${Colours.grey[800]};
`;

export default PlanInfo;
