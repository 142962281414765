import React, {useState, FunctionComponent} from 'react';
import {Password} from '../Inputs';
import {DisplayMessage, DisplayMessageTypes} from '../DisplayMessage';
import {Item, FormTitle, Label, ButtonSpacing, Spacer} from '../../styles/page';
import {PasswordBox} from './ChangePassword.styles';
import {useAuth} from '../../services/auth';
import {UserApi} from '@focusrite-novation/ampify-api';
import {createAuthedApi} from '../../api';
import {parseApiError} from '../../api';

interface ChangePasswordProps {
  apiUrl: string;
}

interface FormErrors {
  existingPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  apiUrl,
}) => {
  const {user} = useAuth();
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingPassword, setExistingPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const minChars = 8;

  const validate = () => {
    const errors: FormErrors = {};

    if (newPassword.length < minChars) {
      errors.newPassword = `Please enter a new password at least ${minChars} characters long`;
    }

    if (confirmPassword !== newPassword) {
      errors.confirmPassword = 'Your passwords do not match. Please try again.';
    }

    return errors;
  };

  const clearPasswordFields = () => {
    setExistingPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const sendRequest = async (errors: FormErrors) => {
    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      const api = createAuthedApi(UserApi, apiUrl);
      await api.putUserDetails(user.id, {
        password: {existing: existingPassword, value: newPassword},
      });

      setUpdated(true);
      clearPasswordFields();
    } catch (error) {
      try {
        const apiError = parseApiError(error);
        const message = apiError?.message;
        if (message?.includes('incorrect')) {
          setErrors({
            existingPassword:
              'Your current password is incorrect. Please try again.',
          });
        } else {
          console.error(message);
          setErrorMessage(message);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(
          'Sorry, there was a problem updating your user information.'
        );
      }
    }

    setIsSubmitting(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    setUpdated(false);
    setIsSubmitting(true);
    setErrorMessage('');
    setErrors({});

    sendRequest(validate());

    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <PasswordBox>
        {updated ? (
          <DisplayMessage
            type={DisplayMessageTypes.SUCCESS}
            data-test-id="change_password_success"
          >
            <div>Successully changed your password</div>
          </DisplayMessage>
        ) : null}
        {errorMessage ? (
          <DisplayMessage data-test-id="change_password_error">
            <div>{errorMessage}</div>
          </DisplayMessage>
        ) : null}
        <FormTitle>Password</FormTitle>
        <Spacer />
        <Item>
          <Label
            error={Boolean(errors.existingPassword)}
            data-test-id="existing_password_label"
            htmlFor="existing_password"
          >
            <b>{errors.existingPassword || 'Existing Password'}</b>
          </Label>
          <Password
            id="existing_password"
            data-test-id="existing_password"
            value={existingPassword}
            error={Boolean(errors.existingPassword)}
            onChange={(e) => {
              setExistingPassword(e?.target?.value);
            }}
          />
        </Item>
        <Item>
          <Label error={Boolean(errors.newPassword)} htmlFor="new_password">
            <b>{errors.newPassword || 'New Password'}</b>
          </Label>
          <Password
            error={Boolean(errors.newPassword)}
            id="new_password"
            data-test-id="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e?.target?.value)}
          />
        </Item>
        <Item>
          <Label
            error={Boolean(errors.confirmPassword)}
            data-test-id="confirm_password_label"
            htmlFor="confirm_password"
          >
            <b>{errors.confirmPassword || 'Confirm Password'}</b>
          </Label>
          <Password
            error={Boolean(errors.confirmPassword)}
            id="confirm_password"
            data-test-id="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e?.target?.value)}
          />
        </Item>
        <Item />
        <ButtonSpacing
          showSpinner={isSubmitting}
          data-test-id="change_password"
        >
          Change Password
        </ButtonSpacing>
      </PasswordBox>
    </form>
  );
};
