import styled from 'styled-components';
import Colours from '../../constants/colours';
// @ts-ignore
import DropdownArrow from '../../images/dropdown-arrow.svg';
import {SelectProps} from './Select.types';

export const Dropdown = styled.select`
  width: 100%;
  border: 1px solid
    ${(p: SelectProps) => (p.error ? Colours.red : Colours.grey[300])};
  border-radius: 0px;
  padding: 1rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: ${Colours.white};
  background-image: url(${DropdownArrow});
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center right 1rem;
  outline: none;
  font-size: 1rem;
  &:active,
  &:focus {
    border: 1px solid ${Colours.green};
  }
`;
