import Colours from '../constants/colours';
import {Pricing, Currency} from '../constants/pricing';

export interface PlanData {
  premiumId: string;
  premiumTitle: string;
  premiumDescription: string;
  premiumContactPrefix: string;
  premiumContactDescription: string;
  premiumContactLink: string;
  registeredId: string;
  registeredTitle: string;
  registeredDescription: string;
  registeredContactPrefix: string;
  registeredContactDescription: string;
  registeredContactLink: string;
  freeId: string;
  freeTitle: string;
  freeDescription: string;
  freeContactPrefix: string;
  freeContactDescription: string;
  freeContactLink: string;
}

export function getPremiumPriceForCurrency(currency: string): string {
  const formatted = currency.toUpperCase();
  return Pricing.hasOwnProperty(formatted)
    ? Pricing[formatted]
    : Pricing[Currency.GBP];
}

export function getCurrencyFromCountryCode(countryCode?: string): Currency {
  if (countryCode === 'GB') {
    return Currency.GBP;
  } else if (countryCode === 'US') {
    return Currency.USD;
  } else if (countryCode && countryCode.length > 0) {
    return Currency.EUR;
  }

  return Currency.GBP;
}

export interface Country {
  code: string;
}

export const parseCountriesFromResponse = (countries: Country[]) => {
  return countries.map((country) => country.code);
};

export function generatePlanDefinitions(planData: PlanData) {
  /*
   * These definitions should be placed in a 'best first' order. i.e. The user will see these in this order,
   * basically you want to make sure that it at least goes 'Subscriber' / 'Freemium' and then the error case at the end...
   */
  return [
    {
      id: planData.premiumId,
      title: planData.premiumTitle,
      description: planData.premiumDescription,
      testid: 'subscriber',
      colour: {
        primary: Colours.subscriberPurple,
        sparkle: Colours.transparent,
      },
      contact: {
        prefix: planData.premiumContactPrefix,
        description: planData.premiumContactDescription,
        link: planData.premiumContactLink,
        external: false,
      },
    },
    {
      id: planData.registeredId,
      title: planData.registeredTitle,
      description: planData.registeredDescription,
      testid: 'registered',
      colour: {
        primary: Colours.registeredBlue,
        sparkle: Colours.white,
      },
      contact: {
        prefix: planData.registeredContactPrefix,
        description: planData.registeredContactDescription,
        link: planData.registeredContactLink,
        external: false,
      },
    },
    {
      id: planData.freeId,
      title: planData.freeTitle,
      description: planData.freeDescription,
      testid: 'free',
      colour: {
        primary: Colours.freeOrange,
        sparkle: Colours.transparent,
      },
      contact: {
        prefix: planData.freeContactPrefix,
        description: planData.freeContactDescription,
        link: planData.freeContactLink,
        external: true,
      },
    },
    {
      id: '',
      title: '',
      description:
        'Your plan cannot be identified, indicating your account may have a problem',
      testid: 'error',
      colour: {
        primary: Colours.grey[900],
        sparkle: Colours.transparent,
      },
      contact: {
        prefix: 'Please click here to',
        description: 'contact support',
        link: 'https://support.ampifymusic.com',
        external: true,
      },
    },
  ];
}
