import React, {FunctionComponent} from 'react';
import {GetUserBillingInfoPaymentMethodCardTypeEnum as CardType} from '@focusrite-novation/ampify-api';

interface CardIconProps {
  cardType: CardType;
  width?: string;
}

const formatCardType = (type: CardType) => {
  return Object.values(CardType).includes(type)
    ? type.toLowerCase().replace(' ', '-')
    : 'unknown';
};

export const CardIcon: FunctionComponent<CardIconProps> = ({
  cardType,
  width,
}) => (
  <img
    src={`/images/cards/${formatCardType(cardType)}.svg`}
    alt="Card icon"
    width={width && width.length > 0 ? width : 'auto'}
  />
);
