import React from 'react';
import {graphql} from 'gatsby';
import {PageProps} from '../types';
import {getApiUrlFromData} from '../utils';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import {AccountDetails} from '../components/AccountDetails';
import {AccountPageQuery} from './__generated__/AccountPageQuery';
import {PageWrapper} from '../styles/page';
import {getPageData} from '../utils';

type AccountPageProps = PageProps<AccountPageQuery>;

const Account = (props: AccountPageProps) => {
  return (
    <Layout>
      <PageWrapper grey>
        <PageWidth narrow>
          <AccountDetails
            planData={getPageData(props.data)}
            apiUrl={getApiUrlFromData(props.data)}
          />
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AccountPageQuery {
    en: allContentfulAmpifyWebPlans(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          premiumId
          premiumTitle
          premiumDescription
          premiumContactPrefix
          premiumContactDescription
          premiumContactLink
          registeredId
          registeredTitle
          registeredDescription
          registeredContactPrefix
          registeredContactDescription
          registeredContactLink
          freeId
          freeTitle
          freeDescription
          freeContactPrefix
          freeContactDescription
          freeContactLink
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`;

export default Account;
